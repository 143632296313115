import Cookies from "universal-cookie";
import axios from "../../utils/axios";
import { removeCookiesByName } from "../../utils/removeCookie";
const cookies = new Cookies();
// Get Conversation
const getConversation = async (conversationId) => {
  if (conversationId) {
    const response = await axios.get(
      `/api/websites/${window.WEBSITE_ID}/conversations/${conversationId}/user`
    );
    return response.data.data;
  }
};
const getOldConversation = async (data) => {
  if (data?.meta?.id) {
    let userType = data?.meta?.optional?.find((el) => el?.key === "userType")?.value
    let q = userType ? `optionalKey=userType&optionalValue=${userType}` : "";
    const response = await axios.get(
      `/api/websites/${window.WEBSITE_ID}/conversations/meta?userId=${data?.meta?.id}${q && `&${q}`}`
    );

    if (
      response.status === 200 &&
      cookies.get(`conversationId${window.WEBSITE_ID}`) !==
      response.data?.data?._id
    ) {
      removeCookiesByName(`conversationId${window.WEBSITE_ID}`);
      const domain =
        "." + window.location.hostname.split(".").slice(-2).join(".");
      cookies.set(
        `conversationId${window.WEBSITE_ID}`,
        response.data?.data?._id,
        {
          path: "/",

          maxAge: 31536000,

          domain: domain,
        }
      );

      return response.data.data;
    }

    return;
  }
};
//  Conversation Rating
const conversationRating = async (data) => {
  const response = await axios.post(
    `/api/websites/${window.WEBSITE_ID}/conversations/${data?.conversationId}/feedback`,
    data?.userFeedBack
  );

  return response.data.data;
};
const getWebsite = async (websiteID) => {
  const response = await axios.get(`/api/clients/websites/user/${websiteID}`);

  return response.data.data;
};
const assignDepartment = async (data) => {
  const response = await axios.put(`/api/websites/${window.WEBSITE_ID}/conversations/${cookies.get(`conversationId${window.WEBSITE_ID}`)}/assign-department`, data);
  return response.data.data;
};
const createSession = async (data) => {
  const response = await axios.post(`/api/websites/${window.WEBSITE_ID}/sessions`, data);
  return response.data.data;
};
const transfer = async (data) => {
  const response = await axios.post(`/api/websites/${window.WEBSITE_ID}/conversation-transfers`, data);
  return response.data.data;
};
const conversationService = {
  getConversation,
  conversationRating,
  getOldConversation,
  getWebsite,
  assignDepartment,
  createSession,
  transfer
};
export default conversationService;
