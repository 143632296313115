import React from 'react'
import ClientChat from "./components/ClientChat/index";
import "./app.scss";
import { useEffect } from "react";
import socket from "./socket/socket";
import poste from './assets/images/poste.png'
import banner from './assets/images/ban_header.jpg'
import calender from './assets/images/icone_date.gif'


function App() {
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
      } else {
        socket.connected === false &&
          (() => {
            socket.connect();
          })();
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const tab = [
    '1847 : création de la première distribution des Postes à Tunis.',
    '14 septembre 1848 : inauguration du premier réseau télégraphique aérien reliant le Bardo, siège officiel du gouvernement beylical, avec Tunis et la Goulette.',
    '01 août 1869 : transformation des distributions des Postes établies à Tunis et à la Goulette en bureaux de télégraphe et remises au receveur.',
    '01 janvier 1875 : ouverture de la première recette de poste de plein exercice à Tunis.',
    '01 juin 1878 : adhésion de la Tunisie à l Union Postale Universelle (UPU)',
    '01 avril 1884 : ouverture du Service de l épargne en Tunisie.',
    '01 juillet 1888 : création de l Office Tunisien des Postes et des Télégraphes.',
    '02 juillet 1888 : émission du premier timbre-poste tunisien',
    '11 juin 1892 : inauguration de l Hôtel des Postes de Tunis.',
    '1895 : démarrage du service de transport du courrier en voiture.',
    '27 mai 1918 : ouverture du Service des Comptes Courants Postaux en Tunisie',
    '28 août 1956 : création de la Caisse d Epargne Nationale Tunisienne.',
    'Juillet 1967 : création du premier centre de tri sis à l avenue de la République.',
    'Mars 1968 : introduction de l informatique dans la poste.',
    '20 mars 1980 : l utilisation du Code Postal en Tunisie.',
    'Mai 1982 : introduction du service Rapid-Poste EMS en Tunisie.',
    'Janvier 1984 : construction et mécanisation du complexe postal de Tunis- Carthage.',
    'Janvier 1996 : démarrage de l aéropostal.',
    '07 novembre 1997 : les timbres-poste sur internet : www.tunisia-stamps.tn',
    'Décembre 1997 : fabrication locale du premier timbre-poste tunisien à « l imprimerie de la Poste Tunisienne ».',
    '02 juin 1998 : promulgation du Code de la Poste réglementant l exercice de l activité postale',
    "15 juin 1998 : création de l'Office National des Postes, dénommé « La Poste Tunisienne », sous la forme d'une entreprise publique à caractère industriel et commercial dotée de l'autonomie financière et de la personnalité morale (démarrage de son activité le 01 janvier 1999).",
    "Mai 1999 : première galerie marchande des timbres-poste tunisiens.",
    "22 novembre 1999 : élaboration de l'Organigramme de l'Office National des Postes bâti sur la dimension commerciale, l'ouverture des horizons pour les cadres et agents, la décentralisation, la création et le renforcement des entités par produit…",
    "	27 décembre 1999 : approbation du Statut Particulier du Personnel de l'Office National des Postes.",
    "29 août 2000 : lancement de la première monnaie électronique tunisienne par la Poste Tunisenne : e - DINAR",
    "	07 novembre 2000 : lancement sur Internet du portail de la Poste : www.poste.tn",
    "Janvier 2001 : mise en service du centre d'appel de la Poste Tunisienne 1828.",
    "	31 Mai 2002 : La Poste Tunisienne a obtenu le Prix Présidentiel de la Meilleure Entreprise Economique qualifiée par ses activités culturelles et sociales.",
    "Août 2002 : certification assurance qualité ISO 9002 du réseau Rapid-Poste.",
    "Novembre 2002 : lancement de la carte de paiement sur Internet « e-DINARPOST » pour les titulaires de comptes courants postaux",
  ]

  return (
    <div className="App">
      <div className='header'>
        <img src={poste} alt="poste-logo-header" className='poste-logo' />
        <img src={banner} alt="poste-banner" className='poste-banner'/>
      </div>
      <div className='history'>
        {tab?.map((el,i) => {
          return <div className='poste-history-element' key={i}>
            <img src={calender} /> {el}
          </div>
        })}
      </div>
      <ClientChat />
    </div>
  );
}

export default App;
